<template>
  <div class="box-loading col d-flex justify-content-center align-items-center" v-if="loading">
    <div
      class="flex-md-column align-self-center"
    >
      <div class="spinner-grow text-center" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div v-else>
    <table :class="classCustom ? classCustom : 'table'">
      <thead>
      <tr>
        <th
          v-for="(column, i) in columns"
          :key="i"
          scope="col"
          @click="column.orderClickCall"
          :class="column.class"
          :style="{
            cursor: column.orderBy ? 'pointer' : 'default',
          }"
        >
          {{ column.name }}
          <a v-if="column.orderBy" href="javascript:" class="text-dark">
            <img src="@/assets/img/order.svg" alt="Ordenar" />
          </a>
        </th>
      </tr>
      </thead>
      <tbody>
      <slot></slot>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'EpTable',
  props: ['columns', 'loading', 'classCustom'],
  methods: {
    change(value) {
      this.$emit('update:change', value);
    }
  }
};
</script>
<style scoped lang="scss">
.box-loading {
  height: 50vh;
}
.table-margin-bottom-0 {
  margin-bottom: 0px!important;
}
.table {
  border: 1px none #e9edef;
  border-bottom-style: solid;
  border-radius: 8px;

  thead {
    tr {
      th {
        &:first-child {
          padding-left: 16px;
        }

        i {
          color: #5e666a;
        }

        padding: 16px 0;
        color: #4b5053;
        font-size: 16px;
      }
    }
  }

  tbody {
    tr {
      td {
        vertical-align: middle;

        &:first-child {
          padding-left: 16px;
        }

        padding: 16px 0;
      }
    }
  }

  .tag-price {
    padding: 4px 8px;
    height: 25px;
    background: #ef999a;
    border-radius: 56px;
    font-size: 12px;
    color: #2f2f2f;
    font-weight: 500;

    &:nth-child(2) {
      background: #84d2ff;
    }

    &:nth-child(3) {
      background: #fff49b;
    }
  }
}

.table-empty {
  color: #7f8588;
}
</style>
