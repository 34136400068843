import monitorNfeService from '@/services/monitorNfe.service';

export const monitorNfeModule = {
  namespaced: true,
  state: {
    list: {
      links: {},
      meta: {},
      results: []
    },
  },
  mutations: {
    fill_monitor_nfe_list(state, list) {
      state.list = list;
    },
  },
  actions: {
    async list({ commit }, url) {
      const response = await monitorNfeService.list(url);
      return commit('fill_monitor_nfe_list', response.data);
    },
  },
  getters: {
    list: (state) => state.list
  }
};
