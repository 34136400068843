import orderService from '@/services/order.service';

export const orderModule = {
  namespaced: true,
  state: {
    orders: {
      links: {},
      meta: {},
      results: []
    },
  },
  mutations: {
    fill_order_list(state, orders) {
      state.orders = orders;
    },
  },
  actions: {
    async list({ commit }, url) {
      const response = await orderService.list(url);
      return commit('fill_order_list', response.data);
    },
  },
  getters: {
    orders: (state) => state.orders
  }
};
