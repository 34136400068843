import { mainAxios } from '@/plugins/axios';

class orderService {
  async list(query) {
    const response = await mainAxios.get(query);
    return response.data;
  }
  async create(data) {
    const response = await mainAxios.post('/ordem-compra', data);
    return response.data;
  }
  async update(id, data) {
    const response = await mainAxios.put('/ordem-compra/' + id, data);
    return response.data;
  }
  async view(id) {
    const response = await mainAxios.get('/ordem-compra/' + id);
    return response.data;
  }
  async delete(id) {
    const response = await mainAxios.delete('/ordem-compra/' + id);
    return response.data;
  }
  async download() {
    const response = await mainAxios.get('/ordem-compra/download');
    return response.data;
  }
}

export default new orderService();
