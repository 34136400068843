import ChatService from '@/services/chat.service';
import chatService from '@/services/chat.service';

export const chat = {
  namespaced: true,
  state: {
    chat: [],
    samples: [],
    general: [],
    quota: [],
    recover: []
  },
  mutations: {
    FILL_CHAT(state, chat) {
      state.chat = chat;
    },
    FILL_SAMPLES(state, samples) {
      state.samples = samples;
    },
    FILL_GENERAL(state, general) {
      state.general = general;
    },
    FILL_QUOTA(state, quota) {
      state.quota = quota;
    },
    FILL_RECOVER(state, recover) {
      state.recover = recover;
    }
  },
  actions: {
    async getRecover({ commit }) {
      let response = await ChatService.messages('RECOVER_SESSION');
      commit('FILL_RECOVER', response.data.data);
    },
    async getQuota({ commit }) {
      let response = await ChatService.messages('QUOTA');
      commit('FILL_QUOTA', response.data.data);
    },
    async getGeneral({ commit }) {
      let response = await ChatService.messages('GENERAL');
      commit('FILL_GENERAL', response.data.data);
    },
    async getSamples({ commit }) {
      let response = await ChatService.messages('SAMPLES');

      // if (response.data.exec.execution === 'Token expirado') {
      //   chatService.token().finally(() => {
      //     ChatService.messages('SAMPLES').then((response) => {
      //       commit('FILL_SAMPLES', response.data.data);
      //     });
      //   });
      //   return;
      // }

      commit('FILL_SAMPLES', response.data.data);
    },
    async getMessage({ commit }, payload) {
      try {
        let response = await ChatService.messages(payload);

        let result;
        let type = 'Paragraph';

        if (response.data.exec.execution === 'Quota diária excedida!') {
          commit('FILL_CHAT', { headers_data: [], data: response.data.data[0], type: type });
          return;
        }


        if (
          (
            !!response.data.data.length ||
            response.data.link !== ''
          ) &&
          String(response.data.exec.execution).indexOf('Linhas afetadas') > 0
        ) {
          type = 'Table';
          result = response.data.link !== '' && !response.data.data
            ? response.data.link
            : JSON.parse(response.data.data);
        }

        if (
          response.data.data.length <= 0
          && response.data.link === ''
        ) {
          result = response.data.exec.execution.replace('- Linhas afetadas: 0', '');
        }

        if (!result) {
          let formatResults = response.data.data;

          if (typeof formatResults === 'object') {
            formatResults = Object.values(formatResults);
          }


          let regex = /FINO-.*/;
          if (
            Array.isArray(formatResults) &&
            (
              payload.toUpperCase() === 'CONTRATAR' ||
              payload.toUpperCase() === 'DESCONTRATAR' ||
              payload.toUpperCase() === 'RECONTRATAR' ||
              payload.search(regex) !== -1
            )
          ) {
            formatResults = formatResults[0];
          }

          result = formatResults;
        }

        commit('FILL_CHAT', {
          headers_data: response.data.headers_data,
          data: result,
          type: type,
          link: response.data.link,
          html: response.data.html,
          text: response.data.text,
          image: response.data.image,
          media: response.data.media
        });

      } catch (error) {
        commit('FILL_CHAT', {
          headers_data: [],
          data: 'Ops! Algo deu errado, tente refazer a pergunta!',
          type: 'Paragraph',
          link: '',
          html: '',
          text: '',
          image: '',
          media: ''
        });
        return;
      }
    }
  },
  getters: {
    chat: (state) => state.chat,
    samples: (state) => state.samples,
    general: (state) => state.general,
    quota: (state) => state.quota,
    recover: (state) => state.recover
  }
};
